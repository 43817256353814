@import "minima";

.image-block-outer-wrapper {
  .image-block-wrapper.has-aspect-ratio {
    padding-bottom: 0 !important;
  }

  .image-caption-wrapper .image-caption p {
    background-color: black;
    color: white;
    padding: 5px;
  }
}

.post-header {
  margin-top: 3em;
  text-align: center;
  margin-bottom: 6em;
  .post-title.p-name {
    text-transform: uppercase;
    font-size: 30px;
  }
}

p > img + em {
  background-color: black;
  color: white;
  padding: 5px;
  display: block;
  font-style: normal;
  font-size: 14px;
}
